import React, { useState, useEffect, useRef } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import Typewriter from 'typewriter-effect/dist/core'
import Marquee from 'react-fast-marquee'
import { Layout } from '../components/layout'
import { Image } from '../components/image'
import { Footer } from '../components/footer'
import { SEO } from '../components/seo'
import { useMedia } from 'react-use'
import { useWindowDimensions } from '../hooks/useWindowDimensions'
import { useLang } from '../hooks/useLang'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const SearchIconSrc = require('../images/search_icon.png')
const SearchIconBlackSrc = require('../images/search_icon_black.png')
const PointerCursorIconSrc = require('../images/pointer_cursor.png')

const IndexPage = () => {
	const { height, width } = useWindowDimensions()

	const [canvas, setCanvas] = useState(null)
	const [context, setContext] = useState(null)
	const [transformScaleX, setTransformScaleX] = useState(1)
	const [transformScaleY, setTransformScaleY] = useState(1)
	const [filterInvert, setFilterInvert] = useState(0)

	const lang = useLang()
	const [showNavigation, setShowNavigation] = useState(false)
	const [nowViewing, setNowViewing] = useState('')

	const [moveCursor, setMoveCursor] = useState(false)
	const [inverSearchCursor, setInverSearchCursor] = useState(false)

	const [showTitleLogo, setShowTitleLogo] = useState(false)

	const [showIntroductionText1, setShowIntroductionText1] = useState(false)
	const [showIntroductionText2, setShowIntroductionText2] = useState(false)
	const [showIntroductionText3, setShowIntroductionText3] = useState(false)
	const [showIntroductionText4, setShowIntroductionText4] = useState(false)
	const [showIntroductionDescription, setShowIntroductionDescription] = useState(false)
	const [moveIntroDescCursor1, setMoveIntroDescCursor1] = useState(false)
	const [moveIntroDescCursor2, setMoveIntroDescCursor2] = useState(false)
	const [moveIntroDescCursor3, setMoveIntroDescCursor3] = useState(false)
	const [selectIntroDescLine1, setSelectIntroDescLine1] = useState(false)
	const [selectIntroDescLine2, setSelectIntroDescLine2] = useState(false)
	const [selectIntroDescLine3, setSelectIntroDescLine3] = useState(false)
	const [selectIntroDescLine4, setSelectIntroDescLine4] = useState(false)
	const [selectIntroDescLine5, setSelectIntroDescLine5] = useState(false)
	const [selectIntroDescLine6, setSelectIntroDescLine6] = useState(false)
	const [selectIntroDescLine7, setSelectIntroDescLine7] = useState(false)
	const [selectIntroDescLine8, setSelectIntroDescLine8] = useState(false)
	const [selectIntroDescLine9, setSelectIntroDescLine9] = useState(false)
	const [selectIntroDescLine10, setSelectIntroDescLine10] = useState(false)
	const [selectIntroDescLine11, setSelectIntroDescLine11] = useState(false)
	const [selectIntroDescLine12, setSelectIntroDescLine12] = useState(false)

	const [showMagazineLogo1, setShowMagazineLogo1] = useState(false)
	const [showMagazineLogo2, setShowMagazineLogo2] = useState(false)
	const [showMagazineLogo3, setShowMagazineLogo3] = useState(false)
	const [showMagazineDescription, setShowMagazineDescription] = useState(false)
	const [moveMagazineDescCursor1, setMoveMagazineDescCursor1] = useState(false)
	const [moveMagazineDescCursor2, setMoveMagazineDescCursor2] = useState(false)
	const [moveMagazineDescCursor3, setMoveMagazineDescCursor3] = useState(false)

	const [selectMagazineDescLine1, setSelectMagazineDescLine1] = useState(false)
	const [selectMagazineDescLine2, setSelectMagazineDescLine2] = useState(false)
	const [selectMagazineDescLine3, setSelectMagazineDescLine3] = useState(false)
	const [selectMagazineDescLine4, setSelectMagazineDescLine4] = useState(false)

	const [showMarketLogo1, setShowMarketLogo1] = useState(false)
	const [showMarketLogo2, setShowMarketLogo2] = useState(false)
	const [showMarketLogo3, setShowMarketLogo3] = useState(false)
	const [showMarketLogo4, setShowMarketLogo4] = useState(false)
	const [showMarketLogo5, setShowMarketLogo5] = useState(false)
	const [showMarketDescription, setShowMarketDescription] = useState(false)
	const [moveMarketDescCursor1, setMoveMarketDescCursor1] = useState(false)
	const [moveMarketDescCursor2, setMoveMarketDescCursor2] = useState(false)
	const [moveMarketDescCursor3, setMoveMarketDescCursor3] = useState(false)
	const [selectMarketDescLine1, setSelectMarketDescLine1] = useState(false)
	const [selectMarketDescLine2, setSelectMarketDescLine2] = useState(false)
	const [selectMarketDescLine3, setSelectMarketDescLine3] = useState(false)
	const [selectMarketDescLine4, setSelectMarketDescLine4] = useState(false)

	const [showMediaLogo1, setShowMediaLogo1] = useState(false)
	const [showMediaLogo2, setShowMediaLogo2] = useState(false)
	const [showMediaLogo3, setShowMediaLogo3] = useState(false)
	const [showMediaDescription, setShowMediaDescription] = useState(false)
	const [moveMediaDescCursor1, setMoveMediaDescCursor1] = useState(false)
	const [moveMediaDescCursor2, setMoveMediaDescCursor2] = useState(false)
	const [moveMediaDescCursor3, setMoveMediaDescCursor3] = useState(false)
	const [selectMediaDescLine1, setSelectMediaDescLine1] = useState(false)
	const [selectMediaDescLine2, setSelectMediaDescLine2] = useState(false)
	const [selectMediaDescLine3, setSelectMediaDescLine3] = useState(false)
	const [selectMediaDescLine4, setSelectMediaDescLine4] = useState(false)
	const [selectMediaDescLine5, setSelectMediaDescLine5] = useState(false)

	const [showBarGalleryLogo1, setShowBarGalleryLogo1] = useState(false)
	const [showBarGalleryLogo2, setShowBarGalleryLogo2] = useState(false)
	const [showBarGalleryLogo3, setShowBarGalleryLogo3] = useState(false)
	const [showBarGalleryDescription, setShowBarGalleryDescription] = useState(false)
	const [showBarGalleryCarousel, setShowBarGalleryCarousel] = useState(false)
	const [moveBarGalDescCursor1, setMoveBarGalDescCursor1] = useState(false)
	const [moveBarGalDescCursor2, setMoveBarGalDescCursor2] = useState(false)
	const [moveBarGalDescCursor3, setMoveBarGalDescCursor3] = useState(false)
	const [selectBarGalDescLine1, setSelectBarGalDescLine1] = useState(false)
	const [selectBarGalDescLine2, setSelectBarGalDescLine2] = useState(false)
	const [selectBarGalDescLine3, setSelectBarGalDescLine3] = useState(false)
	const [selectBarGalDescLine4, setSelectBarGalDescLine4] = useState(false)
	const [selectBarGalDescLine5, setSelectBarGalDescLine5] = useState(false)
	const [selectBarGalDescLine6, setSelectBarGalDescLine6] = useState(false)
	const [selectBarGalDescLine7, setSelectBarGalDescLine7] = useState(false)
	const [selectBarGalDescLine8, setSelectBarGalDescLine8] = useState(false)
	const [selectBarGalDescLine9, setSelectBarGalDescLine9] = useState(false)
	const [selectBarGalDescLine10, setSelectBarGalDescLine10] = useState(false)

	const [showClientsTitle, setShowClientsTitle] = useState(false)
	const [showClientsCarousel, setShowClientsCarousel] = useState(false)

	useEffect(() => {
		new Typewriter('#typewriter', {
			strings: ['INSPIRATION CULT'],
			delay: 150,
			autoStart: true,
			pauseFor: 3600000,
		})

		if (window.location.hash === '' || window.location.hash === '#firstview') {
			setTimeout(() => setMoveCursor(true), 3500)
			setTimeout(() => setInverSearchCursor(true), 4000)
			setTimeout(() => setInverSearchCursor(true), 4000)
			setTimeout(() => window.fullpage_api.moveSectionDown(), 4200)
			setTimeout(() => window.fullpage_api.moveSectionDown(), 6400)
		}

		const canvas = document.getElementById('canvas')
		const canvasContext = canvas.getContext('2d')
		setCanvas(canvas)
		setContext(canvasContext)
	}, [])

	const shiftMargin = 512
	const rWidth = 240
	const rHeight = 160

	useEffect(() => {
		if (canvas !== null && context !== null) {
			// 回転利用の場合は長辺基準で確保
			canvas.width = width;
			canvas.height = height;
			setInterval(drawCanvas, 10)
			setInterval(shiftCanvas, 10)
		}
	}, [canvas, context, height, width])

	const drawCanvas = () => {
		const cWidth = width
		const cHeight = height

		// rWidth x rHeightのサイズ分だけ真面目にノイズを描く
		for (let x = 0; x < rWidth; x++) {
			for (let y = 0; y < rHeight; y++) {
				const n = Math.random() * 256;
				context.fillStyle = '#' + ('00000' + (n << 16 | n << 8 | n).toString(16)).slice(-6)
				context.fillRect(x, y, 1, 1)
			}
		}

		// 真面目に描いた範囲からランダムな位置の1/4サイズをコピーして余白に敷き詰める
		for (let x = 0; x < cWidth; x += rWidth / 2) {
			for (let y = 0; y < cHeight; y += rHeight / 2) {
				if (!(x < rWidth && y < rHeight)) {
					context.putImageData(context.getImageData(Math.random() * rWidth / 2, Math.random() * rHeight / 2, Math.ceil(rWidth / 2), Math.ceil(rHeight / 2)), x, y)
				}
			}
		}
	}

	const shiftCanvas = () => {
		// 位置シフト
		canvas.style.left = (- Math.random() * shiftMargin) + 'px'
		canvas.style.top = (- Math.random() * shiftMargin) + 'px'

		// 90度単位の回転、左右上下ネガポジ反転も加え、見かけ上のバリエーションを増やす
		// 50%の確率で左右反転
		if (Math.random() > .5) {
			setTransformScaleX(transformScaleX * -1)
		}
		// 50%の確率で上下反転
		if (Math.random() > .5) {
			setTransformScaleY(transformScaleY * -1)
		}
		// 90度単位の角度をランダムに選択
		const transformRotate = Math.floor(Math.random() * 4) * 180
		canvas.style.transform = 'scale(' + transformScaleX + ', ' + transformScaleY + ') rotate(' + transformRotate + 'deg)'
		// 50%の確率でネガポジ反転
		if (Math.random() > .5) {
			canvas.style.filter = 'invert(' + (setFilterInvert(filterInvert ^ 1)) + ')'
		}
	}

	const onLeave = (origin, destination, direction) => {
		if (destination.anchor === 'firstview' || destination.anchor === 'title' || destination.anchor === 'about' || destination.anchor === 'clients') {
			setShowNavigation(false)
		} else {
			setShowNavigation(true)
		}

		setNowViewing(destination.anchor)

		if (destination.anchor === 'owned') {
			setTimeout(() => setShowIntroductionText1(true), 1000)
			setTimeout(() => setShowIntroductionText2(true), 1100)
			setTimeout(() => setShowIntroductionText3(true), 1200)
			setTimeout(() => setShowIntroductionText4(true), 1300)
			setTimeout(() => setShowIntroductionDescription(true), 1800)

			setTimeout(() => setMoveIntroDescCursor1(true), 3000)
			setTimeout(() => setMoveIntroDescCursor2(true), 4000)

			setTimeout(() => setSelectIntroDescLine1(true), 4000)
			setTimeout(() => setSelectIntroDescLine2(true), 4300)
			setTimeout(() => setSelectIntroDescLine3(true), 4600)
			setTimeout(() => setSelectIntroDescLine4(true), 4900)
			setTimeout(() => setSelectIntroDescLine5(true), 5200)
			setTimeout(() => setSelectIntroDescLine6(true), 5500)
			setTimeout(() => setSelectIntroDescLine7(true), 5800)
			setTimeout(() => setSelectIntroDescLine8(true), 6100)
			setTimeout(() => setSelectIntroDescLine9(true), 6400)
			setTimeout(() => setSelectIntroDescLine10(true), 6700)
			setTimeout(() => setSelectIntroDescLine11(true), 7000)
			setTimeout(() => setSelectIntroDescLine12(true), 7300)

			setTimeout(() => {
				setSelectIntroDescLine1(false)
				setSelectIntroDescLine2(false)
				setSelectIntroDescLine3(false)
				setSelectIntroDescLine4(false)
				setSelectIntroDescLine5(false)
				setSelectIntroDescLine6(false)
				setSelectIntroDescLine7(false)
				setSelectIntroDescLine8(false)
				setSelectIntroDescLine9(false)
				setSelectIntroDescLine10(false)
				setSelectIntroDescLine11(false)
				setSelectIntroDescLine12(false)
			}, 8000)

			setTimeout(() => setMoveIntroDescCursor3(true), 8500)
		}

		// if (destination.anchor === 'title') {
		// 	setTimeout(() => setShowTitleLogo(true), 1000)
		// }

		if (destination.anchor === 'magazine') {
			setTimeout(() => setShowMagazineLogo1(true), 1000)
			setTimeout(() => setShowMagazineLogo2(true), 1100)
			setTimeout(() => setShowMagazineLogo3(true), 1200)
			setTimeout(() => setShowMagazineDescription(true), 1700)

			setTimeout(() => setMoveMagazineDescCursor1(true), 3000)
			setTimeout(() => setMoveMagazineDescCursor2(true), 4000)

			setTimeout(() => setSelectMagazineDescLine1(true), 4000)
			setTimeout(() => setSelectMagazineDescLine2(true), 4300)
			setTimeout(() => setSelectMagazineDescLine3(true), 4600)
			setTimeout(() => setSelectMagazineDescLine4(true), 4900)

			setTimeout(() => {
				setSelectMagazineDescLine1(false)
				setSelectMagazineDescLine2(false)
				setSelectMagazineDescLine3(false)
				setSelectMagazineDescLine4(false)
			}, 7000)

			setTimeout(() => setMoveMagazineDescCursor3(true), 7500)
		}

		if (destination.anchor === 'market') {
			setTimeout(() => setShowMarketLogo1(true), 1000)
			setTimeout(() => setShowMarketLogo2(true), 1100)
			setTimeout(() => setShowMarketLogo3(true), 1200)
			setTimeout(() => setShowMarketLogo4(true), 1300)
			setTimeout(() => setShowMarketLogo5(true), 1400)
			setTimeout(() => setShowMarketDescription(true), 1900)

			setTimeout(() => setMoveMarketDescCursor1(true), 3200)
			setTimeout(() => setMoveMarketDescCursor2(true), 4200)

			setTimeout(() => setSelectMarketDescLine1(true), 4200)
			setTimeout(() => setSelectMarketDescLine2(true), 4500)
			setTimeout(() => setSelectMarketDescLine3(true), 4800)
			setTimeout(() => setSelectMarketDescLine4(true), 5100)

			setTimeout(() => {
				setSelectMarketDescLine1(false)
				setSelectMarketDescLine2(false)
				setSelectMarketDescLine3(false)
				setSelectMarketDescLine4(false)
			}, 7200)

			setTimeout(() => setMoveMarketDescCursor3(true), 7700)
		}

		if (destination.anchor === 'media') {
			setTimeout(() => setShowMediaLogo1(true), 1000)
			setTimeout(() => setShowMediaLogo2(true), 1100)
			setTimeout(() => setShowMediaLogo3(true), 1200)
			setTimeout(() => setShowMediaDescription(true), 1700)

			setTimeout(() => setMoveMediaDescCursor1(true), 3000)
			setTimeout(() => setMoveMediaDescCursor2(true), 4000)

			setTimeout(() => setSelectMediaDescLine1(true), 4000)
			setTimeout(() => setSelectMediaDescLine2(true), 4300)
			setTimeout(() => setSelectMediaDescLine3(true), 4600)
			setTimeout(() => setSelectMediaDescLine4(true), 4900)
			setTimeout(() => setSelectMediaDescLine5(true), 5200)

			setTimeout(() => {
				setSelectMediaDescLine1(false)
				setSelectMediaDescLine2(false)
				setSelectMediaDescLine3(false)
				setSelectMediaDescLine4(false)
				setSelectMediaDescLine5(false)
			}, 7000)

			setTimeout(() => setMoveMediaDescCursor3(true), 7500)
		}

		if (destination.anchor === 'bar-gallery') {
			setTimeout(() => setShowBarGalleryLogo1(true), 1000)
			setTimeout(() => setShowBarGalleryLogo2(true), 1100)
			setTimeout(() => setShowBarGalleryLogo3(true), 1200)
			setTimeout(() => setShowBarGalleryCarousel(true), 2000)
			setTimeout(() => setShowBarGalleryDescription(true), 2000)

			setTimeout(() => setMoveBarGalDescCursor1(true), 3000)
			setTimeout(() => setMoveBarGalDescCursor2(true), 4000)

			setTimeout(() => setSelectBarGalDescLine1(true), 4000)
			setTimeout(() => setSelectBarGalDescLine2(true), 4300)
			setTimeout(() => setSelectBarGalDescLine3(true), 4600)
			setTimeout(() => setSelectBarGalDescLine4(true), 4900)
			setTimeout(() => setSelectBarGalDescLine5(true), 5200)
			setTimeout(() => setSelectBarGalDescLine6(true), 5500)
			setTimeout(() => setSelectBarGalDescLine7(true), 5800)
			setTimeout(() => setSelectBarGalDescLine8(true), 6100)
			setTimeout(() => setSelectBarGalDescLine9(true), 6400)
			setTimeout(() => setSelectBarGalDescLine10(true), 6700)

			setTimeout(() => {
				setSelectBarGalDescLine1(false)
				setSelectBarGalDescLine2(false)
				setSelectBarGalDescLine3(false)
				setSelectBarGalDescLine4(false)
				setSelectBarGalDescLine5(false)
				setSelectBarGalDescLine6(false)
				setSelectBarGalDescLine7(false)
				setSelectBarGalDescLine8(false)
				setSelectBarGalDescLine9(false)
				setSelectBarGalDescLine10(false)
			}, 8000)

			setTimeout(() => setMoveBarGalDescCursor3(true), 8500)
		}

		if (destination.anchor === 'clients') {
			setTimeout(() => setShowClientsTitle(true), 1000)
			setTimeout(() => setShowClientsCarousel(true), 1200)
		}
	}

	const isPc = useMedia('(min-width: 1026px)');
	const isSp = useMedia('(max-width: 599px)');

	return (
		<div style={{ backgroundColor: '#111' }}>
			<div className={`navigation ${showNavigation ? '' : 'hide'}`}>
				<ul>
					<li className="menu-wit">
						<a href="#wit" className={nowViewing === 'wit' ? 'active' : ''}>
							Inc.
						</a>
					</li>
					<li className="border" />
					<li className="menu-bar-gallery">
						<a href="#bar-gallery" className={nowViewing === 'bar-gallery' ? 'active' : ''}>
							BAR&GALLERY
						</a>
					</li>
					<li className="pc border" />
					<li className="pc menu-market">
						<a href="#market" className={nowViewing === 'market' ? 'active' : ''}>
							MARKET
						</a>
					</li>
					<li className="pc border" />
					<li className="pc menu-media">
						<a href="#media" className={nowViewing === 'media' ? 'active' : ''}>
							MEDIA
						</a>
					</li>
					<li className="pc border" />
					<li className="pc menu-magazine">
						<a href="#magazine" className={nowViewing === 'magazine' ? 'active' : ''}>
							MAGAZINE
						</a>
					</li>
					<li className="border" />
					<li className="menu-owned">
						<a href="#owned" className={nowViewing === 'owned' ? 'active' : ''}>
							ICM
						</a>
					</li>
					<li className="border" />
					<li className="menu-works">
						<a href="#works" className={nowViewing === 'works' ? 'active' : ''}>
							WORKS
						</a>
					</li>
					<li className="border" />
					<li className="menu-service">
						<a href="#service" className={nowViewing === 'service' ? 'active' : ''}>
							SERVICE
						</a>
					</li>
				</ul>
			</div>

			<Layout>
				<SEO
					title='Welcome to ICM'
					description='INSPIRAION CULTへようこそ。'
					keywords={['inspiration cult', 'icm', 'インスピレーションカルト', 'マガジン', 'ストリートカルチャー', 'インスタ']}
				/>
				<ReactFullpage
					anchors={['firstview', 'title', 'about', 'service', 'works', 'owned', 'magazine', 'media', 'market', 'bar-gallery', 'wit', 'clients']}
					scrollingSpeed={1000}
					onLeave={onLeave.bind(this)}
					licenseKey='F0A7D6D9-54894C86-8AEC67FF-415E7674'
					render={({ state, fullpageApi }) => (
						<ReactFullpage.Wrapper>
							<div className="section firstview-section">
								<div className="typewriter-wrapper">
									<div id="typewriter" className="typewriter" />
									<div className="search-icon-wrapper">
										<img className="search-icon" src={inverSearchCursor ? SearchIconSrc : SearchIconBlackSrc} alt="search_icon" />
										<img className={`cursor ${moveCursor ? 'after' : ''}`} src={PointerCursorIconSrc} alt="cursor_icon" />
									</div>
								</div>
							</div>

							<div className="section title-section">
								<div className="logo">
									{/* <div className={`animation1 ${showTitleLogo ? 'show' : ''}`}> */}
									<Image filename="mainlogo.png" width={500} height={120} alt="mainlogo" />
									{/* </div> */}
								</div>
								<canvas id="canvas" />
							</div>

							<div className="section about-section">
								<div className="title">Bespoke</div>
								<div className="description">
									{lang === 'ja' ? (
										<>
											<div>
												<div>私たちはプロモーションを機軸として、想いを大切に、話し合いを重ねながら</div>
												<div>目的フェーズにあわせたプランを設計しています。</div>
											</div>
											<div>
												<div>ビジュアルストーリーテラーとして、広告PR戦略を包括的に打ちたてる</div>
												<div>ムービー・写真・ウェブサイトを制作。</div>
												<div>ワンストップでマルチなクリエイティブを実現させて目標達成へ向かう</div>
												<div>世界に一つだけのBespokeなプロジェクトをプロデュースします。</div>
											</div>
										</>
									) : (
										<div className="en">
											<div>
												<div>We design business strategies that meet the objectives of each phase of the project,</div>
												<div>with promotion being our key element.</div>
												<div>We discuss with each other with mutual respect for each other's ideas.</div>
											</div>
											<div>
												<div>As visual storytellers, we create comprehensive advertising and PR strategies.</div>
												<div>We produce movies, photos, and websites.</div>
												<div>One-stop, multi-faceted creative services to achieve your goals.</div>
												<div>We produce one-of-a-kind customized projects.	</div>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="section service-section">
								<div className="title">SERVICE</div>

								<div className="services-list">
									<div className="service-item">
										<div className="service-title">Project Design</div>
										<div className="service-detail">
											{lang === 'ja' ?
												<>
													目標を達成するための戦略設計から具体的な方法の選定など、俯瞰的かつ包括的に事業を創出します。プロジェクトに欠かせない初期段階の事業計画、CFマーケティングから、アイデアやプロセス、リソース、成果物などを戦略的に計画・調整します。'
												</>
											:
												<div>
													<div>We create projects from a bird's eye view</div>
													<div>and in a comprehensive manner, from strategic design</div>
													<div>to the selection of specific methods to achieve the goals.</div>
													<div>We strategically plan and coordinate ideas, processes,</div>
													<div>resources, and deliverables from the initial business plan</div>
													<div>and CF marketing, which are essential to the project.</div>
												</div>}
										</div>
									</div>
									<div className="service-item">
										<div className="service-title">Promotion Produce</div>
										<div className="service-detail">
											{lang === 'ja' ?
												<>
													プロジェクトやキャンペーンの広告宣伝活動、セールスプロモーションを担います。PR機能を活かしたメディア戦略やインフルエンサーマーケティング戦略を設計して、費用対効果の優れた施策を実行します。
												</>
											:
											<>	
													<div>We are responsible for advertising and sales promotion activities for</div>
													<div>projects and campaigns. We design media and influencer marketing</div>
													<div>strategies utilizing PR functions to offer cost-effective business solutions.</div>
											</>}
										</div>
									</div>
									<div className="service-item">
										<div className="service-title">Creative Produce</div>
										<div className="service-detail">
											{lang === 'ja' ?
												<>
													プロジェクトのゴールに向けて、最適なフォトグラファー、ビデオグラファーをはじめとするクリエイターをセットアップしてプロデュースを担い、個々のスペシャリティを最大限に引き出す、ベストのクリエイティブ制作を導き出します。アムステルダムのクリエイティブスタジオPost Neonと業務提携。CG・VFX・モーショングラフィックスなどの視覚効果を設計・実装します。
												</>
											:
											<>
												<div>We bring together a team of creatives, including the best photographers</div>
												<div>and videographers, and other creators to meet the goals of the project.</div>
												<div>We build a team that brings out the best in them and provides the client</div>
												<div>with the optimal creative solution. We work with Post Neon, a creative</div>
												<div>studio in Amsterdam, to design and implement visual effects such as CG,</div>
												<div>VFX, and motion graphics.</div>
											</>}
										</div>
									</div>
									<div className="service-item">
										<div className="service-title">CI, Website, SEO</div>
										<div className="service-detail">
											{lang === 'ja' ?
												<>
													企業の独自性を打ち立てる軸となる理念やブランドなど、本質となる上位概念を構築するCIを設計します。インタラクティブなwebsite制作、企業理念やブランディング、市場トレンドを織り込んだニーズ理解とキーワード精選、投下先などのSEO対策を最適化して構築します。
												</>
											:
											<>
												<div>We design a CI that builds the essential, high-level concepts such as</div>
												<div>philosophy and brand, which are the axis for establishing the uniqueness</div>
												<div>of the company. We create an interactive website, optimize corporate</div>
												<div>philosophy and branding, understand the needs and select keywords</div>
												<div>that incorporate market trends, and optimize SEO measures such as</div>
												<div>the location of the site.</div>
											</>}
										</div>
									</div>
								</div>
							</div>

							<div className="section works-section">
								<a href="/works">
									<div className="title">WORKS</div>
									<div className="all">ALL</div>
								</a>
							</div>

							<div className="section owned-section">
								<div className="wrapper">
									<div className="title">
										<div className={`animation1 ${showIntroductionText1 ? 'show' : ''}`}>
											<div>STREET</div>
										</div>
										<div className={`animation1 ${showIntroductionText2 ? 'show' : ''}`}>
											<div>CULTURE</div>
										</div>
										<div className={`animation1 ${showIntroductionText3 ? 'show' : ''}`}>
											<div>CONNECTING</div>
										</div>
										<div className={`animation1 ${showIntroductionText4 ? 'show' : ''}`}>
											<div>MINDS</div>
										</div>
									</div>
									<div className={`description fade-in ${showIntroductionDescription ? 'show' : ''} ${lang === 'en' ? 'en' : ''}`}>
										<img className={`cursor ${moveIntroDescCursor1 ? 'move1' : ''} ${moveIntroDescCursor2 ? 'move2' : ''} ${moveIntroDescCursor3 ? 'move3' : ''}`} src={PointerCursorIconSrc} alt="cursor_icon" />
										<div className="pc">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectIntroDescLine1 ? 'selection' : ''}>INSPIRAION CULTは、Magazine, Media, Market 3つのICMを展開しています。</span>
													</div>
													<div>
														<span className={selectIntroDescLine2 ? 'selection' : ''}>すべてはNYのストリートフォトムーブメントから始まり、フォトグラファーたちがルーフトップに登ることで</span>
													</div>
													<div>
														<span className={selectIntroDescLine3 ? 'selection' : ''}>これまでにない視点からの写真とムービーがストリートに捉えられ、いつもの街の新しい領域を</span>
													</div>
													<div>
														<span className={selectIntroDescLine4 ? 'selection' : ''}>見せるカルチャーから東京で『INSPIRATION CULT MAGAZINE』は生まれました。</span>
													</div>
													<div>
														<span className={selectIntroDescLine5 ? 'selection' : ''}>ここから進化をくり返しながら『MEDIA』へとビジュアル体験をつないでいます。</span>
													</div>
													<div>
														<span className={selectIntroDescLine6 ? 'selection' : ''}>そうして培った創造性とマインド、ボーダレスなコミュニケーションから</span>
													</div>
													<div>
														<span className={selectIntroDescLine7 ? 'selection' : ''}>感性の楽しさを手にとることができる『MARKET』でリアルの体験をお届けしています。</span>
													</div>
													<div>
														<span className={selectIntroDescLine8 ? 'selection' : ''}>INSPIRAION CULTは、クリエイティブエージェンシーとしてより広く、より楽しく</span>
													</div>
													<div>
														<span className={selectIntroDescLine9 ? 'selection' : ''}>より新しいよろこびをつくりエンパワーメントとストーリーを大切につなげていきます。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectIntroDescLine1 ? 'selection' : ''}>We’re INSPIRATION CULT operating 3 elements of “ICM” it’s Magazine, Media and Market.</span>
													</div>
													<div>
														<span className={selectIntroDescLine2 ? 'selection' : ''}>Started out with photographs and movies, and transformed them to the “MAGAZINE”.</span>
													</div>
													<div>
														<span className={selectIntroDescLine3 ? 'selection' : ''}>We keep evolving from there, and carry out the visual experiences into the “MEDIA”.</span>
													</div>
													<div>
														<span className={selectIntroDescLine4 ? 'selection' : ''}>The creativity, mind, and borderless communications has been cultivated.</span>
													</div>
													<div>
														<span className={selectIntroDescLine5 ? 'selection' : ''}>We can deliver the real experiences through the MARKET which enables people to touch the joyness of the sensitivity.</span>
													</div>
													<div>
														<span className={selectIntroDescLine6 ? 'selection' : ''}>INSPIRATION CULT creates brand new joyness as a creative agency, and spreads more fun widely.</span>
													</div>
													<div>
														<span className={selectIntroDescLine7 ? 'selection' : ''}>We focus on connecting the empowerment and the stories.</span>
													</div>
												</>
											)}
										</div>
										<div className="sp">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectIntroDescLine1 ? 'selection' : ''}>INSPIRAION CULTは、Magazine, Media, Market 3つのICMを展開しています。</span>
													</div>
													<div>
														<span className={selectIntroDescLine2 ? 'selection' : ''}>すべてはNYのストリートフォトムーブメントから始まり、</span>
													</div>
													<div>
														<span className={selectIntroDescLine3 ? 'selection' : ''}>フォトグラファーたちがルーフトップに登ることで</span>
													</div>
													<div>
														<span className={selectIntroDescLine4 ? 'selection' : ''}>これまでにない視点からの写真とムービーがストリートに</span>
													</div>
													<div>
														<span className={selectIntroDescLine5 ? 'selection' : ''}>捉えられ、いつもの街の新しい領域を見せるカルチャーから</span>
													</div>
													<div>
														<span className={selectIntroDescLine6 ? 'selection' : ''}>東京で『INSPIRATION CULT MAGAZINE』は生まれました。ここから進化をくり返しながら『MEDIA』へと</span>
													</div>
													<div>
														<span className={selectIntroDescLine7 ? 'selection' : ''}>ビジュアル体験をつないでいます。そうして培った</span>
													</div>
													<div>
														<span className={selectIntroDescLine8 ? 'selection' : ''}>創造性とマインド、ボーダレスなコミュニケーションから</span>
													</div>
													<div>
														<span className={selectIntroDescLine9 ? 'selection' : ''}>感性の楽しさを手にとることができる『MARKET』で</span>
													</div>
													<div>
														<span className={selectIntroDescLine10 ? 'selection' : ''}>リアルの体験をお届けしています。INSPIRAION CULTは、</span>
													</div>
													<div>
														<span className={selectIntroDescLine11 ? 'selection' : ''}>クリエイティブエージェンシーとしてより広く、より楽しく</span>
													</div>
													<div>
														<span className={selectIntroDescLine12 ? 'selection' : ''}>より新しいよろこびをつくりエンパワーメントとストーリーを大切につなげていきます。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectIntroDescLine1 ? 'selection' : ''}>
															We’re INSPIRATION CULT operating 3 elements of
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine2 ? 'selection' : ''}>
															“ICM” it’s Magazine, Media and Market.Started out with
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine3 ? 'selection' : ''}>
															photographs and movies, and transformed them to
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine4 ? 'selection' : ''}>
															the “MAGAZINE”. We keep evolving from there,
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine5 ? 'selection' : ''}>
															and carry out the visual experiences into the “MEDIA”.
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine6 ? 'selection' : ''}>
															The creativity, mind, and borderless communications
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine7 ? 'selection' : ''}>
															has been cultivated.  We can deliver the real experiences
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine8 ? 'selection' : ''}>
															through the MARKET which enables people to touch
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine9 ? 'selection' : ''}>
															the joyness of the sensitivity.
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine10 ? 'selection' : ''}>
															INSPIRATION CULT creates brand new joyness as
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine11 ? 'selection' : ''}>
															a creative agency, and spreads more fun widely.
														</span>
													</div>
													<div>
														<span className={selectIntroDescLine12 ? 'selection' : ''}>
															We focus on connecting the empowerment and the stories.
														</span>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="section icm-section magazine">
								<div className="logo">
									<a href="https://inspirationcultmag.com/back-number/" target="_blank" rel="noreferrer">
										<div className={`animation2 ${showMagazineLogo1 ? 'show' : ''}`}>
											<Image filename="maglogo1.png" alt="maglogo1" />
										</div>
										<div className={`animation2 ${showMagazineLogo2 ? 'show' : ''}`}>
											<Image filename="maglogo2.png" alt="maglogo2" />
										</div>
										<div className={`animation2 ${showMagazineLogo3 ? 'show' : ''}`}>
											<Image filename="maglogo3.png" alt="maglogo3" />
										</div>
									</a>
									<div className={`description fade-in ${showMagazineDescription ? 'show' : ''} ${lang === 'en' ? 'en' : ''}`}>
										<img className={`cursor ${moveMagazineDescCursor1 ? 'move1' : ''} ${moveMagazineDescCursor2 ? 'move2' : ''} ${moveMagazineDescCursor3 ? 'move3' : ''}`} src={PointerCursorIconSrc} alt="cursor_icon" />
										<div className="pc">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectMagazineDescLine1 ? 'selection' : ''}>フォトグラファーとビデオグラファーたちが写しだす</span>
													</div>
													<div>
														<span className={selectMagazineDescLine2 ? 'selection' : ''}>クリエイティブコンテンツを通して、</span>
													</div>
													<div>
														<span className={selectMagazineDescLine3 ? 'selection' : ''}>まさに今、この世界に起きている</span>
													</div>
													<div>
														<span className={selectMagazineDescLine4 ? 'selection' : ''}>事事の核心を発信するマガジンです。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectMagazineDescLine1 ? 'selection' : ''}>The MAGAZINE disseminates the core of the global subjects in real time through creative contents</span>
													</div>
													<div>
														<span className={selectMagazineDescLine3 ? 'selection' : ''}>by photographers and videographers.</span>
													</div>
												</>
											)}
										</div>
										<div className="sp">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectMagazineDescLine1 ? 'selection' : ''}>フォトグラファーとビデオグラファーたちが写しだす</span>
													</div>
													<div>
														<span className={selectMagazineDescLine2 ? 'selection' : ''}>クリエイティブコンテンツを通して、まさに今、</span>
													</div>
													<div>
														<span className={selectMagazineDescLine3 ? 'selection' : ''}>この世界に起きている事事の核心を発信する</span>
													</div>
													<div>
														<span className={selectMagazineDescLine4 ? 'selection' : ''}>マガジンです。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectMagazineDescLine1 ? 'selection' : ''}>
															The MAGAZINE disseminates the core of
														</span>
													</div>
													<div>
														<span className={selectMagazineDescLine2 ? 'selection' : ''}>
															the global subjects in real time through creative
														</span>
														<div>
														</div>
														<span className={selectMagazineDescLine3 ? 'selection' : ''}>
															contents by photographers and videographers.
														</span>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="section icm-section media">
								<div className="logo">
									<a href="https://www.youtube.com/channel/UCAQ3_dduY70InXOFqNu7teA/" target="_blank" rel="noreferrer">
										<div className={`animation2 ${showMediaLogo1 ? 'show' : ''}`}>
											<Image filename="medialogo1.png" alt="medialogo1" />
										</div>
										<div className={`animation2 ${showMediaLogo2 ? 'show' : ''}`}>
											<Image filename="medialogo2.png" alt="medialogo2" />
										</div>
										<div className={`animation2 ${showMediaLogo3 ? 'show' : ''}`}>
											<Image filename="medialogo3.png" alt="medialogo3" />
										</div>
									</a>
									<div className={`description fade-in ${showMediaDescription ? 'show' : ''} ${lang === 'en' ? 'en' : ''}`}>
										<img className={`cursor ${moveMediaDescCursor1 ? 'move1' : ''} ${moveMediaDescCursor2 ? 'move2' : ''} ${moveMediaDescCursor3 ? 'move3' : ''}`} src={PointerCursorIconSrc} alt="cursor_icon" />
										<div className="pc">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectMediaDescLine1 ? 'selection' : ''}>すべてがまぶしかったあの頃から今日へ。</span>
													</div>
													<div>
														<span className={selectMediaDescLine2 ? 'selection' : ''}>そして、生き方そのものが大切になる、</span>
													</div>
													<div>
														<span className={selectMediaDescLine3 ? 'selection' : ''}>これからのために。</span>
													</div>
													<div>
														<span className={selectMediaDescLine4 ? 'selection' : ''}>ストリートで活躍するキーパーソンたちと</span>
													</div>
													<div>
														<span className={selectMediaDescLine5 ? 'selection' : ''}>つくるYouTubeコンテンツです。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectMediaDescLine1 ? 'selection' : ''}>The days we felt the world was dazzling, and move it on today. The lifestyle will be special for the future.</span>
													</div>
													<div>
														<span className={selectMediaDescLine3 ? 'selection' : ''}>The content on YouTube which is created with people from street cultures.</span>
													</div>
												</>
											)}
										</div>
										<div className="sp">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectMediaDescLine1 ? 'selection' : ''}>すべてがまぶしかったあの頃から今日へ。</span>
													</div>
													<div>
														<span className={selectMediaDescLine2 ? 'selection' : ''}>そして、生き方そのものが大切になる、</span>
													</div>
													<div>
														<span className={selectMediaDescLine3 ? 'selection' : ''}>これからのために。</span>
													</div>
													<div>
														<span className={selectMediaDescLine4 ? 'selection' : ''}>ストリートで活躍するキーパーソンたちとつくる</span>
													</div>
													<div>
														<span className={selectMediaDescLine5 ? 'selection' : ''}>YouTubeコンテンツです。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectMediaDescLine1 ? 'selection' : ''}>The days we felt the world was dazzling,</span>
													</div>
													<div>
														<span className={selectMediaDescLine2 ? 'selection' : ''}>and move it on today. The lifestyle will be special</span>
													</div>
													<div>
														<span className={selectMediaDescLine3 ? 'selection' : ''}>for the future. The content on YouTube which is</span>
													</div>
													<div>
														<span className={selectMediaDescLine4 ? 'selection' : ''}>created with people from street cultures.</span>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="section icm-section market">
								<div className="logo">
									<a href="https://inspirationcultmarket.com/" target="_blank" rel="noreferrer">
										<div className={`animation2 ${showMarketLogo1 ? 'show' : ''}`}>
											<Image filename="marketlogo1.png" alt="marketlogo1" />
										</div>
										<div className={`animation2 ${showMarketLogo2 ? 'show' : ''}`}>
											<Image filename="marketlogo2.png" alt="marketlogo2" />
										</div>
										<div className={`animation2 ${showMarketLogo3 ? 'show' : ''}`}>
											<Image filename="marketlogo3.png" alt="marketlogo3" />
										</div>
										<div className={`animation2 ${showMarketLogo4 ? 'show' : ''}`}>
											<Image filename="marketlogo4.png" alt="marketlogo4" />
										</div>
										<div className={`animation2 ${showMarketLogo5 ? 'show' : ''}`}>
											<Image filename="marketlogo5.png" alt="marketlogo5" />
										</div>
									</a>
									<div className={`description fade-in ${showMarketDescription ? 'show' : ''} ${lang === 'en' ? 'en' : ''}`}>
										<img className={`cursor ${moveMarketDescCursor1 ? 'move1' : ''} ${moveMarketDescCursor2 ? 'move2' : ''} ${moveMarketDescCursor3 ? 'move3' : ''}`} src={PointerCursorIconSrc} alt="cursor_icon" />
										<div className="pc">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectMarketDescLine1 ? 'selection' : ''}>クリエイティブマインドを大切に</span>
													</div>
													<div>
														<span className={selectMarketDescLine2 ? 'selection' : ''}>これまでにない新しい楽しさをすべての人へ。</span>
													</div>
													<div>
														<span className={selectMarketDescLine3 ? 'selection' : ''}>ありとあらゆる境界を越えて</span>
													</div>
													<div>
														<span className={selectMarketDescLine4 ? 'selection' : ''}>カルチャーをつなぐマーケットです。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectMarketDescLine1 ? 'selection' : ''}>Focusing on the creative mind, and delivering brand new joyness to all of the people.</span>
													</div>
													<div>
														<span className={selectMarketDescLine3 ? 'selection' : ''}>The MARKET connects different cultures without any borders.</span>
													</div>
												</>
											)}
										</div>
										<div className="sp">
											{lang === 'ja' ? (
												<>
													<div>
														<span className={selectMarketDescLine1 ? 'selection' : ''}>クリエイティブマインドを大切にこれまでにない</span>
													</div>
													<div>
														<span className={selectMarketDescLine2 ? 'selection' : ''}>新しい楽しさをすべての人へ。</span>
													</div>
													<div>
														<span className={selectMarketDescLine3 ? 'selection' : ''}>ありとあらゆる境界を越えてカルチャーを</span>
													</div>
													<div>
														<span className={selectMarketDescLine4 ? 'selection' : ''}>つなぐマーケットです。</span>
													</div>
												</>
											) : (
												<>
													<div>
														<span className={selectMarketDescLine1 ? 'selection' : ''}>Focusing on the creative mind, and delivering</span>
													</div>
													<div>
														<span className={selectMarketDescLine2 ? 'selection' : ''}>brand new joyness to all of the people.</span>
													</div>
													<div>
														<span className={selectMarketDescLine3 ? 'selection' : ''}>The MARKET connects different cultures without</span>
													</div>
													<div>
														<span className={selectMarketDescLine4 ? 'selection' : ''}>any borders.</span>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="section icm-section bar-gallery">
								<div className="wrapper">
									<div className="wrapper-in-wrapper">
										<a href="https://inspirationcultmag.com/bar-gallery/" target="_blank" rel="noreferrer">
											<div className="logo">
												<div className={`animation2 ${showBarGalleryLogo1 ? 'show' : ''}`}>
													<Image filename="bargallogo1.png" alt="bargallogo1" />
												</div>
												<div className={`animation2 ${showBarGalleryLogo2 ? 'show' : ''}`}>
													<Image filename="bargallogo2.png" alt="bargallogo2" />
												</div>
												<div className={`animation2 ${showBarGalleryLogo3 ? 'show' : ''}`}>
													<Image filename="bargallogo3.png" alt="bargallogo3" />
												</div>
											</div>
										</a>
										<div className={`description fade-in ${showBarGalleryDescription ? 'show' : ''} ${lang === 'en' ? 'en' : ''}`}>
											<img className={`cursor ${moveBarGalDescCursor1 ? 'move1' : ''} ${moveBarGalDescCursor2 ? 'move2' : ''} ${moveBarGalDescCursor3 ? 'move3' : ''}`} src={PointerCursorIconSrc} alt="cursor_icon" />
											<div className="pc">
												{lang === 'ja' ? (
													<>
														<div>
															<span className={selectBarGalDescLine1 ? 'selection' : ''}>ICMをリアルに体感できる空間</span>
														</div>
														<div>
															<span className={selectBarGalDescLine2 ? 'selection' : ''}>INSPIRATION CULT BAR AND GALLERYが東京渋谷東にあります。</span>
														</div>
														<div>
															<span className={selectBarGalDescLine3 ? 'selection' : ''}>撮影スペース、ギャラリー、イベント開催など</span>
														</div>
														<div>
															<span className={selectBarGalDescLine4 ? 'selection' : ''}>ヘッドオフィスとしての機能を備え、</span>
														</div>
														<div>
															<span className={selectBarGalDescLine5 ? 'selection' : ''}>ゲストをお迎えするadidasシークレットルームを設えております。</span>
														</div>
														<div>
															<span className={selectBarGalDescLine6 ? 'selection' : ''}>夜間は、どなたでもご利用いただけるBARを営業。</span>
														</div>
														<div>
															<span className={selectBarGalDescLine7 ? 'selection' : ''}>人と人とカルチャーが交じり合う、より開かれた空間として</span>
														</div>
														<div>
															<span className={selectBarGalDescLine8 ? 'selection' : ''}>エグゼクティブな時間をお楽しみください。</span>
														</div>
													</>
												) : (
													<>
														<div>
															<span className={selectBarGalDescLine1 ? 'selection' : ''}>The place where you can feel ICM in real</span>
														</div>
														<div>
															<span className={selectBarGalDescLine2 ? 'selection' : ''}>INSPIRATION CULT  BAR AND GALLERY located in Shibuya HIgashi,Tokyo.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine3 ? 'selection' : ''}>Photo shooting space, gallery, and space to hold events.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine4 ? 'selection' : ''}>It has functions as a head office of ICM, and there is a secret guest room of adidas.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine5 ? 'selection' : ''}>Anyone is free to come by the bar for your nightlife.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine6 ? 'selection' : ''}>The space is open widely, and people and different cultures will get together from here.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine7 ? 'selection' : ''}>Please enjoy the executive moment with ICM.</span>
														</div>
													</>
												)}
											</div>
											<div className="sp ">
												{lang === 'ja' ? (
													<>
														<div>
															<span className={selectBarGalDescLine1 ? 'selection' : ''}>ICMをリアルに体感できる空間</span>
														</div>
														<div>
															<span className={selectBarGalDescLine2 ? 'selection' : ''}>INSPIRATION CULT BAR AND GALLERYが</span>
														</div>
														<div>
															<span className={selectBarGalDescLine3 ? 'selection' : ''}>東京渋谷東にあります。</span>
														</div>
														<div>
															<span className={selectBarGalDescLine4 ? 'selection' : ''}>撮影スペース、ギャラリー、イベント開催など</span>
														</div>
														<div>
															<span className={selectBarGalDescLine5 ? 'selection' : ''}>ヘッドオフィスとしての機能を備え、ゲストをお迎えする</span>
														</div>
														<div>
															<span className={selectBarGalDescLine6 ? 'selection' : ''}>adidasシークレットルームを設えております。</span>
														</div>
														<div>
															<span className={selectBarGalDescLine7 ? 'selection' : ''}>夜間は、どなたでもご利用いただけるBARを営業。</span>
														</div>
														<div>
															<span className={selectBarGalDescLine8 ? 'selection' : ''}>人と人とカルチャーが交じり合う、より開かれた</span>
														</div>
														<div>
															<span className={selectBarGalDescLine9 ? 'selection' : ''}>空間としてエグゼクティブな時間をお楽しみください。</span>
														</div>
													</>
												) : (
													<div>
														<div>
															<span className={selectBarGalDescLine1 ? 'selection' : ''}>The place where you can feel ICM in real</span>
														</div>
														<div>
															<span className={selectBarGalDescLine2 ? 'selection' : ''}>INSPIRATION CULT BAR AND GALLERY</span>
														</div>
														<div>
															<span className={selectBarGalDescLine3 ? 'selection' : ''}>located in Shibuya HIgashi,Tokyo. Photo shooting</span>
														</div>
														<div>
															<span className={selectBarGalDescLine4 ? 'selection' : ''}>space, gallery, and space to hold events.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine5 ? 'selection' : ''}>It has functions as a head office of ICM,</span>
														</div>
														<div>
															<span className={selectBarGalDescLine6 ? 'selection' : ''}>and there is a secret guest room of adidas.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine7 ? 'selection' : ''}>Anyone is free to come by the bar for your nightlife.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine8 ? 'selection' : ''}>The space is open widely, and people and different</span>
														</div>
														<div>
															<span className={selectBarGalDescLine9 ? 'selection' : ''}>cultures will get together from here.</span>
														</div>
														<div>
															<span className={selectBarGalDescLine10 ? 'selection' : ''}>Please enjoy the executive moment with ICM.</span>
														</div>
													</div>
												)}
											</div>

											<div className="address">
												{lang === 'ja' ?
													<>
														INSPIRATION CULT BAR & GALLERY<br />
														〒150-0011 東京都渋谷区東2丁目22-3<br />
														定休日：bar 日・月曜日・不定休 / gallery 土・日・月曜日
													</> : 
													<>
														INSPIRATION CULT BAR & GALLERY<br />
														2-22-3 Higashi, Shibuya, Tokyo 1500011 Japan<br />
														closed on: bar Sunday, Monday and irregularly<br />
														gallery Saturday, Sunday, and Monday
													</>
												}
											</div>
										</div>
									</div>
								</div>

								<Marquee
									className={`fade-in ${showBarGalleryCarousel ? 'show' : ''}`}
									gradient={false}
									speed={isPc ? 10 : 50}
								>
									<Image filename="bargal_p1.jpg" alt="bargal_p1" height={200} width={300} />
									<Image filename="bargal_p2.jpg" alt="bargal_p2" height={200} width={300} />
									<Image filename="bargal_p3.jpg" alt="bargal_p3" height={200} width={300} />
									<Image filename="bargal_p4.jpg" alt="bargal_p4" height={200} width={300} />
									<Image filename="bargal_p5.jpg" alt="bargal_p5" height={200} width={300} />
									<Image filename="bargal_p6.jpg" alt="bargal_p6" height={200} width={300} />
									<Image filename="bargal_p7.jpg" alt="bargal_p7" height={200} width={300} />
									<Image filename="bargal_p8.jpg" alt="bargal_p8" height={200} width={300} />
									<Image filename="bargal_p9.jpg" alt="bargal_p9" height={200} width={300} />
									<Image filename="bargal_p10.jpg" alt="bargal_p10" height={200} width={300} />
								</Marquee>
							</div>

							<div className="section wit-section">
								<div className="pc">
									<Image filename="wit_logo.png" width={174} height={54} alt="wit_logo" />
								</div>
								<div className="sp">
									<Image filename="wit_logo.png" width={116} height={36} alt="wit_logo" />
								</div>
								<div className="wit-description">
									{lang === 'ja' ? 
										<>
											私たち株式会社WITは、「プロジェクトをデザインする」マルチタスク型クリエイティブチームです。<br />
											プロジェクトといっても、新規事業や既存事業、商品開発事業など様々です。<br />
											そういった多種多様なプロジェクトをデザインするために<br />
											PLからCFなど、数字の試算から実施ブランディングデザインまでを行います。<br />
											事業者やプロジェクトの想いを大切にしながら、「クリエイティブ」＋「ロジック」＋「ハート」を軸に<br />
											ゴールを決めて、小さくても大きくても、どの様なアウトプットも可能にします！<br />
											ロジカルな考えをベースにクリエイティブな翼を持ち、[STREET MIND]というリラックスした心と<br />
											発想をキーにプロジェクトを羽ばたかせます。<br />
											人懐っこく、プロフェッショナルに１つ１つの仕事を遂行し、人の繋がりを大切に<br />
											良い未来が作れる会社でありたいと思っています。
										</>
										:
										<div className="en">
											Derived from the Latin "conceptus,"<br/>
											meaning "conception, idea, concept".<br/>
											A "conceptor" is someone who comes up<br/>
											with ideas and concepts.<br/>
											They provide new value and generate creative<br/>
											and innovative ideas to achieve business goals.<br/>
											<br/>
											WIT is our multitasking creative team,<br/>
											and our mission is "designing projects".<br/>
											We handle various projects, such as start-up businesses,<br/>
											existing businesses, and product development.<br/>
											In order to design such a project, we provide services<br/>
											ranging from PL to CF and other numerical estimations<br/>
											to implementation branding design, creative production<br/>
											and direction of website production, photography and<br/>
											videography. We always respect the projects and vision<br/>
											of the business owners, and set a goal by focusing on<br/>
											"creativity", "logic" and "hearts".<br/>
											We make any output possible even if it's small or big.<br/>
											Having creative wings based on logical ideas, and let the<br/>
											<br/>
											project fly with creative ideas and with a relaxed heart.<br/>
											Be friendly and professionally carry out every single<br/>
											business, and value the connections between people.<br/>
											We would like to be a company that can create a good future.<br/>
										</div>
									}
								</div>
								<a href="http://witdesignplaats.com/" target="_blank" rel="noreferrer">
									<div className="wit-link">COMPANY WEB SITE</div>
								</a>
							</div>

							<div className="section clients-section">
								<div className="wrapper">
									<div className="title">
										<div className={`fade-in ${showClientsTitle ? 'show' : ''}`}>
											<div>CLIENTS</div>
										</div>
									</div>
									<div className={`fade-in ${showClientsCarousel ? 'show' : ''}`}>
										<Marquee
											gradient={false}
											speed={50}
										>
											<a href="https://www.youtube.com/watch?v=uQ0C2bam-ak" target="_blank" rel="noreferrer">
												<Image filename="001_adidas.png" alt="001_adidas" height={200} width={248} />
											</a>
											<a href="https://www.youtube.com/watch?v=EEgGG5mNNjM" target="_blank" rel="noreferrer">
												<Image filename="002_jw.png" alt="002_jw" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/4203/" target="_blank" rel="noreferrer">
												<Image filename="003_kool.png" alt="003_kool" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/3802/" target="_blank" rel="noreferrer">
												<Image filename="004_northface.png" alt="004_northface" height={200} width={248} />
											</a>
											<a href="https://www.youtube.com/watch?v=n8rzCBegRRY" target="_blank" rel="noreferrer">
												<Image filename="005_humanmade.png" alt="005_humanmade" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/3210/" target="_blank" rel="noreferrer">
												<Image filename="006_iwc.png" alt="006_iwc" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/1911/" target="_blank" rel="noreferrer">
												<Image filename="007_mini.png" alt="007_mini" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/4112/" target="_blank" rel="noreferrer">
												<Image filename="008_princehotel.png" alt="008_princehotel" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/tommyhilfiger/" target="_blank" rel="noreferrer">
												<Image filename="009_tommy.png" alt="009_tommy" height={200} width={248} />
											</a>
											<a href="https://pulp.four-one-seven.jp" target="_blank" rel="noreferrer">
												<Image filename="010_bay.png" alt="010_bay" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/3492/" target="_blank" rel="noreferrer">
												<Image filename="011_breitling.png" alt="011_breitling" height={200} width={248} />
											</a>
											<a href="https://www.youtube.com/watch?v=nLzyX-OFJv8" target="_blank" rel="noreferrer">
												<Image filename="012_ohmine.png" alt="012_ohmine" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/3401/" target="_blank" rel="noreferrer">
												<Image filename="013_adobe.png" alt="013_adobe" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/858/" target="_blank" rel="noreferrer">
												<Image filename="014_charriol.png" alt="014_charriol" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/3853/" target="_blank" rel="noreferrer">
												<Image filename="015_tenga.png" alt="015_tenga" height={200} width={248} />
											</a>
											<a href="https://inspirationcultmag.com/works_content/1937/" target="_blank" rel="noreferrer">
												<Image filename="016_mhd.png" alt="016_mhd" height={200} width={248} />
											</a>
											<Image filename="017_canon.png" alt="017_canon" height={200} width={248} />
											<Image filename="018_suntory.png" alt="018_suntory" height={200} width={248} />
											<Image filename="019_beams.png" alt="019_beams" height={200} width={248} />
											<Image filename="020_apple.png" alt="020_apple" height={200} width={248} />
											<Image filename="021_seiko.png" alt="021_seiko" height={200} width={248} />
											<Image filename="022_toshiba.png" alt="022_toshiba" height={200} width={248} />
											<Image filename="023_magicstick.png" alt="023_magicstick" height={200} width={248} />
											<Image filename="024_tokyu.png" alt="024_tokyu" height={200} width={248} />
											<Image filename="025_chrome.png" alt="025_chrome" height={200} width={248} />
											<Image filename="026_adorama.png" alt="026_adorama" height={200} width={248} />
											<Image filename="027_applebum.png" alt="027_applebum" height={200} width={248} />
											<Image filename="028_bs.png" alt="028_bs" height={200} width={248} />
											<Image filename="029_casio.png" alt="029_casio" height={200} width={248} />
											<Image filename="030_coach.png" alt="030_coach" height={200} width={248} />
											<Image filename="031_diageokirin.png" alt="031_diageokirin" height={200} width={248} />
											<Image filename="032_ducati.png" alt="032_ducati" height={200} width={248} />
											<Image filename="033_dw.png" alt="033_dw" height={200} width={248} />
											<Image filename="034_footlocker.png" alt="034_footlocker" height={200} width={248} />
											<Image filename="035_galaxy.png" alt="035_galaxy" height={200} width={248} />
											<Image filename="036_gstar_raw.png" alt="036_gstar_raw" height={200} width={248} />
											<Image filename="037_lanzzo.png" alt="037_lanzzo" height={200} width={248} />
											<Image filename="038_maka.png" alt="038_maka" height={200} width={248} />
											<Image filename="039_missbish.png" alt="039_missbish" height={200} width={248} />
											<Image filename="040_mitsukoshi.png" alt="040_mitsukoshi" height={200} width={248} />
											<Image filename="041_ritzcarlton.png" alt="041_ritzcarlton" height={200} width={248} />
											<Image filename="042_ss.png" alt="042_ss" height={200} width={248} />
											<Image filename="043_tobacco.png" alt="043_tobacco" height={200} width={248} />
											<Image filename="044_toyota.png" alt="044_toyota" height={200} width={248} />
											<Image filename="045_victor.png" alt="045_victor" height={200} width={248} />
										</Marquee>
									</div>
								</div>
								<Footer />
							</div>
						</ReactFullpage.Wrapper>
					)}
				/>
			</Layout>
		</div>
	)
}

export default IndexPage
